import { Component } from '@angular/core';
import { NavParams, Platform } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { ModalService } from '../../component/modal/modal.service';
import { CrudService } from '../../services/crud.service';
import { UtilService } from '../../services/util.service';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

@Component({
    selector: 'room-service-modal',
    templateUrl: 'room-service.html',
    styleUrls: ['room-service.scss'],
    providers: [InAppBrowser]
})
export class RoomServiceModal {
    propertyId: string = ''
    room: string = ''
    buttons: any = []
    html: string = ''
    maxHeight = (this._platform.height() * 0.6) + 'px';
    maxImgHeight = (this._platform.height() * 0.2) + 'px';
    selectedButton: any = {};
    callButton: any;
    constructor(
        private modalCtrl: ModalController,
        private crudService: CrudService,
        private utilService: UtilService,
        private navParams: NavParams,
        private _platform: Platform,
        private _modalService: ModalService,
        private iab: InAppBrowser) {
        if (this.navParams.get("buttons")) {
            this.buttons = this.navParams.get("buttons");
        } 
        if (this.navParams.get("html")) {
            this.html = this.navParams.get("html");
        }
        if (this.navParams.get("callButton")) {
            this.callButton = this.navParams.get("callButton");
        }
    }

    ngOnInit() {
        this.propertyId = localStorage.getItem('propertyId')
        this.room = localStorage.getItem('room')
      
    }

    dismiss() {
        this.modalCtrl.dismiss();
    }

    async requestRoomService(roomServiceBtn: any) {
        this.selectedButton = roomServiceBtn;
        switch (roomServiceBtn.type) {
            case 'room-service-issue':
                this.roomServiceIssueType(roomServiceBtn)
                break;
            case 'room-service-quantity':
                this.roomServiceQuantityType(roomServiceBtn);
                break;
            case 'room-service':
                this.showConfirmation();
                break;
            case 'url':
                const url = roomServiceBtn?.options?.url.replace('{{ROOM}}', localStorage.getItem('room'));
                this.iab.create(url);
                break;
            default:
                break;
        }

    }

    async roomServiceType(roomServiceLabel: string) {
        try {
            this.utilService.showLoader();
            let requestBody = {
                request: roomServiceLabel,
                propertyId: this.propertyId,
                roomNumber: this.room,
                quantity: this.selectedButton?.selectedQuantity?.value || 0,
                comment: `${this.selectedButton?.selectedIssue ? `${this.selectedButton?.selectedIssue || ''} : ${this.selectedButton?.selectedNotes || ''}` : `${this.selectedButton?.selectedNotes || ''}` } `
            };
            let response = await this.crudService.saveData('room-service', requestBody).toPromise();
            this.utilService.hideLoader();
            this.utilService.showToast(`Request save successfully`);
        } catch (error) {
            this.utilService.hideLoader();
        }
    }

    async roomServiceQuantityType(btn: any) {
        this.selectedButton.modalId = 'room-service-quantity';
        this.selectedButton.roomServiceQuantityTypeOpen = true;
        this.selectedButton.selectOptions = Array.from({ length: btn?.options?.qtyLimit || 0 }, (_, index) => ({
            value: index + 1,
            label: `${index + 1}`
        }));
        this.selectedButton.selectedQuantity = this.selectedButton?.selectOptions?.length ? this.selectedButton.selectOptions[0] : 0;
        this._modalService.open('room-service-quantity');
    }

    async roomServiceIssueType(btn: any) {
        this.selectedButton.modalId = 'room-service-issue';
        this.selectedButton.selectedIssue = '';
        this.selectedButton.selectedNotes = '';
        this.selectedButton.roomServiceIssueTypeOpen = true;
        this.selectedButton.selectedIssue = this.selectedButton?.options?.inputOptions?.length ? this.selectedButton?.options?.inputOptions[0] : '';
        this._modalService.open('room-service-issue');
    }

    async showConfirmation() {
        this._modalService.open('confirmation-popup');
    }

    async onYes() {
        this._modalService.close('confirmation-popup');
        if (this.selectedButton.modalId)
            this._modalService.close(this.selectedButton.modalId);
        await this.roomServiceType(this.selectedButton.label);
    }

    onNo() {
        this._modalService.close('confirmation-popup');
    }
}
